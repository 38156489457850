import React, { useEffect } from 'react'
import NormalLayoutContainer from './shared/NormalLayoutContainer'
import { PageTitle } from '../components/page-title'
import { Spinner } from './shared/Spinner'
import FormExplanation from './shared/FormExplanation'
import UserProfileEditForm from '../components/user-profile/UserProfileEditForm'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from '../routes'
import { useDispatch, useSelector } from 'react-redux'
import useQuery from '../hooks/useQuery'
import useMutation from '../hooks/useMutation'
import QUERY_KEYS from '../graphql/queryKeys'
import userQuery from '../graphql/queries/user'
import USER_ACTIONS from '../store/user/userActions'
import updateUser from '../graphql/mutations/updateUser'
import { onlyNumbers } from '../utilities/stringUtilities'
import notify from '../utilities/notify'

export default function UserProfileEditPage () {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector(({ user: { jwt, user } }) => ({ jwt, user }))

  // We could just pull the user from Redux, but in this specific case its probably best we refresh the user from the server
  const { data: userData, isFetching } = useQuery(
    [QUERY_KEYS.user],
    userQuery
  )

  const { mutate: updateUserMutation, isLoading } = useMutation(updateUser, {
    onSuccess () {
      notify('success', 'User successfully updated')
      history.push(ROUTES.userProfile)
    },
    onError () {
      notify('error', 'Failed to update user')
    }
  })

  useEffect(function onUserDataChange () {
    if (!userData) return
    dispatch({ type: USER_ACTIONS.SET_USER, payload: { user: userData.user } })
  }, [dispatch, userData])

  function handleSubmit (updatedUserData) {
    updatedUserData.primaryPhone = `+1${onlyNumbers(updatedUserData.prettyPrimaryPhone)}`
    updateUserMutation({ ...user, ...updatedUserData })
  }

  const messages = [
    'You can edit your personal information here.',
    'Your name as you enter it on this page will be used in activity history, driver assignments, and elsewhere across the system.',
    'Note that changing any information on this page also changes your name as displayed in any ticket or job history.'
  ]

  return (
    <NormalLayoutContainer showBackLink>
      <div className='container-fluid'>
        <PageTitle title='Edit User Profile'>
          <Link to={ROUTES.userProfile} className='btn btn-lg btn-link push-down-very-small pull-right'>
            Cancel
          </Link>
        </PageTitle>

        {
          isFetching || !user
            ? <Spinner isFetching />
            : (
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                  <UserProfileEditForm
                    user={user}
                    onSubmit={handleSubmit}
                    disabled={isLoading}
                  />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1'>
                  <div className='row'>
                    <FormExplanation messages={messages} />
                  </div>
                </div>
              </div>
              )
        }
      </div>
    </NormalLayoutContainer>
  )
}
