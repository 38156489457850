import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import PropTypes from 'prop-types'

export default function DefaultWeight ({ weightOptions, defaultWeightUnit, handleChangeDefaultWeight, handleUpdateWeight }) {
  return (
    <div className={'list-group-item'}>
      <div>
        <div className={styles.title}>Select Default Weight Unit</div>
        <div className={cn(styles.description, styles.mdText)}>
          The selected default weight unit will appear as the default unit for
          the drivers on the app
        </div>
      </div>

      <div className={cn(styles.container)}>
        <div className={cn(styles.selectWidth)}>
          <select
            onChange={handleChangeDefaultWeight}
            defaultValue={defaultWeightUnit}
            className='form-control'>
            {weightOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleUpdateWeight}
          className={cn(
            styles.btnLink,
            'dis-btn dis-btn-sm dis-btn-primary-lt mt-2'
          )}>
          <span className={cn(styles.mdText, styles.fontMedium)}>Update Default</span>
        </button>
      </div>
    </div>
  )
}

DefaultWeight.propTypes = {
  weightOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  defaultWeightUnit: PropTypes.string.isRequired,
  handleChangeDefaultWeight: PropTypes.func.isRequired,
  handleUpdateWeight: PropTypes.func.isRequired
}
