import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import styles from './index.module.scss'
import cn from 'classnames'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import moment from 'moment'
import {
  TextInput
} from '../TextInput'
import { enumToTitleCase } from '../../utilities/stringUtilities'
import { TextareaInput } from '../TextareaInput'
import { DatePickerInput } from '../DatePickerInput'
import { TimeInput } from '../TimeInput'
import { SelectInput } from '../SelectInput'

const validate = (values) => {
  const errors = {}

  if (!values.date && values.enabled?.date) {
    errors.date = 'Required'
  }

  if (!values.billingStatus && values?.enabled?.billingStatus) {
    errors.billingStatus = 'Required'
  }

  if (!values.ticketTypeId && values?.enabled.ticketTypeId) {
    errors.ticketTypeId = 'Required'
  }

  if (!values.resourceTypeId && values?.enabled?.resourceTypeId) {
    errors.resourceTypeId = 'Required'
  }

  if (!values.driverId && values?.enabled?.driverId) {
    errors.driverId = 'Required'
  }

  if (values.requestedStartTime && !values.requestedEndTime) {
    errors.requestedEndTime = 'Required if Start Time'
  }

  if (values.requestedEndTime && !values.requestedStartTime) {
    errors.requestedStartTime = 'Required if End Time'
  }

  if (values.requestedStartTime && values.requestedEndTime) {
    const momentRequestedStartTime = moment(values.requestedStartTime, 'HH:mm')
    const momentRequestedEndTime = moment(values.requestedEndTime, 'HH:mm')
    const invalidTimes =
      values.requestedStartTime &&
      values.requestedEndTime &&
      momentRequestedEndTime.isBefore(momentRequestedStartTime)
    const sameTimes =
      values.requestedStartTime &&
      values.requestedEndTime &&
      momentRequestedEndTime.isSame(momentRequestedStartTime)

    if (invalidTimes || sameTimes) {
      errors.requestedEndTime = 'End Time must be after Start Time'
      errors.requestedStartTime = 'Start Time must be before End Time'
    }
  }

  if (!values.weightUnitsOfMeasure && values.enabled.weightUnitsOfMeasure) {
    errors.weightUnitsOfMeasure = 'Required'
  }

  return errors
}

export default function BulkTicketsEditForm ({
  onSubmit,
  ticketTypes,
  resourceTypes,
  billingStatuses,
  weightUnits,
  isFetching,
  checkInvoiced,
  initialValues,
  users
}) {
  const userSelectOptions = () => {
    let options = map(users, u => [u.id, `${u.firstName} ${u.lastName}`])
    options = options.sort()
    options = fromPairs(options)
    options.null = '- Unassigned -'
    return options
  }

  const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  )

  Condition.propTypes = {
    when: PropTypes.string.isRequired,
    is: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
  }

  const handleCheckBox = (args, state) => {
    const [value, field, fieldsToUpdate] = args
    state.fields[field].change(value)
    if (!value) {
      fieldsToUpdate.map(updatingField => state.fields[updatingField].change(null))
    }
  }

  return (
    <div className='dis-panel'>
      <div className='dis-panel-body'>
        <Form onSubmit={onSubmit}
          className='dis-panel'
          initialValues={initialValues}
          validate={values => validate(values)}
          mutators={{ handleCheckBox }}>
          {({ handleSubmit, valid, values, form }) => (
            <form className='dis-panel-body' onSubmit={handleSubmit} autoComplete='off'>
              <div className={styles.formSection}>
                <div className={styles.checkboxContainer}>
                  <Field name='enabled.date'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(!values.enabled.date, 'enabled.date', ['date'])}
                        />
                        <label htmlFor='enabled.date'>Change Date</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.date' is={true}>
                    <Field
                      name='date'
                      label='Date'
                      component={DatePickerInput}
                    />
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field
                    name='enabled.ticketTypeId'>
                    {({ input }) => (
                      <>
                        <input
                          {...input}
                          type='checkbox'
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.ticketTypeId,
                            'enabled.ticketTypeId',
                            ['ticketTypeId']
                          )}
                        />
                        <label htmlFor='enabled.ticketTypeId'>Change Ticket Type</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.ticketTypeId' is={true}>
                    <Field name='ticketTypeId'>
                      {({ input }) => (
                        <div className='form-group'>
                          <label htmlFor='ticketTypeId' className='control-label'>Ticket Type</label>
                          <select
                            {...input}
                            type='select'
                            className='form-control'>
                            <option key='placeholder' value=''></option>
                            {ticketTypes.map((tt) =>
                              (
                                <option
                                  value={tt.id}
                                  key={tt.id}>
                                  {`${tt.name} (${tt.shortCode})`}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                    </Field>
                  </Condition>
                </div>
              </div>
              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.resourceTypeId'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.resourceTypeId,
                            'enabled.resourceTypeId',
                            ['resourceTypeId']
                          )}
                        />
                        <label htmlFor='enabled.resourceTypeId'>Change Asset Type</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.resourceTypeId' is={true}>
                    <Field name='resourceTypeId'>
                      {({ input }) => (
                        <div className='form-group'>
                          <label htmlFor='resourceTypeId' className='control-label'>Asset Type</label>
                          <select
                            {...input}
                            type='select'
                            className='form-control'>
                            <option key='placeholder' value=''></option>
                            {resourceTypes.map((rt) =>
                              (
                                <option
                                  value={rt.id}
                                  key={rt.id}>
                                  {`${rt.name} (${rt.shortCode})`}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                    </Field>
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.driverId'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(!values.enabled.driverId, 'enabled.driverId', ['driverId'])}
                        />
                        <label htmlFor='enabled.driverId'>Change Driver</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.driverId' is={true}>
                    <Field
                      name='driverId'
                      label='Assigned Driver'
                      alphabetize
                      options={userSelectOptions()}
                      component={SelectInput}
                    />
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.weight'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(!values.enabled.weight, 'enabled.weight', ['weight'])}

                        />
                        <label htmlFor='enabled.weight'>Change Weight</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.weight' is={true}>
                    <Field
                      name='weight'
                      label={'Weight'}
                      component={TextInput}
                    />
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field
                    name='enabled.weightUnitsOfMeasure'>
                    {({ input }) => (
                      <>
                        <input
                          {...input}
                          id='enabled.weightUnitsOfMeasure'
                          type='checkbox'
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.weightUnitsOfMeasure,
                            'enabled.weightUnitsOfMeasure',
                            ['weightUnitsOfMeasure']
                          )}
                        />
                        <label htmlFor='enabled.weightUnitsOfMeasure'>Change Weight Unit</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.weightUnitsOfMeasure' is={true}>
                    <Field name='weightUnitsOfMeasure'>
                      {({ input }) => (
                        <div className='form-group'>
                          <label htmlFor='weightUnitsOfMeasure' className='control-label'>Weight Unit</label>
                          <select
                            {...input}
                            id='weightUnitsOfMeasure'
                            type='select'
                            className='form-control'>
                            <option key='placeholder' value=''></option>
                            {weightUnits?.map((weightUnitsOptions) =>
                              (
                                <option
                                  value={weightUnitsOptions.value}
                                  key={weightUnitsOptions.value}>
                                  {weightUnitsOptions.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )}
                    </Field>
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.billingNotes'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.billingNotes,
                            'enabled.billingNotes',
                            ['billingNotes']
                          )}
                        />
                        <label htmlFor='enabled.billingNotes'>Change Billing Notes</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.billingNotes' is={true}>
                    <Field
                      component={TextareaInput}
                      label='Billing Notes (Optional)'
                      name='billingNotes'
                      placeholder='(Billing Notes can be used to track quoted prices or any other billing information. Drivers do not see
                      billing notes on the mobile app.)'
                      maxLength='1000'
                    />
                  </Condition>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.requestedTimesEnabled'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.requestedTimesEnabled,
                            'enabled.requestedTimesEnabled',
                            ['requestedStartTime', 'requestedEndTime']
                          )}
                        />
                        <label htmlFor='enabled.requestedTimesEnabled'>Change Time</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.requestedTimesEnabled' is={true}>
                    <div className={styles.formSection}>
                      <div className={styles.timeContainer}>
                        <Field
                          name='requestedStartTime'
                          label='Requested Time Range'
                          component={TimeInput}
                        />
                      </div>
                      <div className={styles.timeContainer}>
                        <Field
                          name='requestedEndTime'
                          label='Requested Time Range'
                          component={TimeInput}
                        />
                      </div>
                    </div>
                  </Condition>
                </div>
              </div>
              <div className={styles.formSection}>
                <div className={cn('push-down-very-small', styles.checkboxContainer)}>
                  <Field name='enabled.billingStatus'>
                    {({ input }) => (
                      <>
                        <input
                          type='checkbox'
                          {...input}
                          onChange={() => form.mutators.handleCheckBox(
                            !values.enabled.billingStatus,
                            'enabled.billingStatus',
                            ['billingStatus']
                          )}
                        />
                        <label htmlFor='enabled.billingStatus'>Change Billing Status</label>
                      </>
                    )}
                  </Field>
                </div>
                <div className={styles.inputContainer}>
                  <Condition when='enabled.billingStatus' is={true}>
                    <Field
                      disabled={!values?.enabled?.billingStatus}
                      name='billingStatus'
                      label='Billing Status'
                      component={SelectInput}
                      options={fromPairs(map(Object.keys(billingStatuses), opt => [opt, enumToTitleCase(opt)]))}
                    />
                  </Condition>
                </div>
              </div>
              {checkInvoiced && <div className={styles.formSection}>
                <div className={styles.qboMessage}>
                  One or more tickets selected have been invoiced and their billing status can only be changed to paid.
                  These tickets will only update if applicable upon save.
                </div>
              </div>}

              {(values?.enabled?.date || values?.enabled?.ticketTypeId || values?.enabled?.resourceTypeId || values?.enabled?.driverId ||
                values?.enabled?.weight || values?.enabled?.weightUnitsOfMeasure || values?.enabled?.billingNotes || values?.enabled?.requestedTimesEnabled ||
                values?.enabled?.billingStatus) && (
                  <>
                    <div className={styles.messageContainer}>
                      <span className={styles.messageContainerTitle}>Please note:</span>
                      <span>It may take some time to edit multiple tickets. You will receive a notification if an error occurs.</span>
                    </div>
                    <div className={styles.btnContainer}>
                      <button
                        type='submit'
                        className='dis-btn dis-btn-lg dis-btn-success'
                        disabled={!valid || isFetching}>
                        <span>Save Changes on Tickets</span>
                        <i className='material-icons dis-btn-icon'>check</i>
                      </button>
                    </div>
                  </>
              )}
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

BulkTicketsEditForm.propTypes = {
  isFetching: PropTypes.bool,
  weightUnits: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resourceTypes: PropTypes.array.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  billingStatuses: PropTypes.object.isRequired,
  users: PropTypes.array,
  checkInvoiced: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired
}
