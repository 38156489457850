import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from '../../utilities/dayjs'
import { stringToTime, enumToTitleCase } from '../../utilities/stringUtilities'
import TicketStatusLabel from '../TicketStatusLabel'
import { TicketFlag } from '../../pages/shared/TicketFlag'
import NoResults from '../../pages/shared/NoResults'
import CursorPagination from '../cursor-pagination'
import BillingStatusIcon from '../billing-status-display'

function TableHeader ({ label, columnName, onSortChange, sort }) {
  const currentlySorted = sort.column === columnName

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className='cursor-pointer' onClick={() => onSortChange(columnName)}>
      {label}
      {currentlySorted && (
        <span className='pull-right'>{ sort.direction === 'asc' ? <>&uarr;</> : <>&darr;</> }</span>
      )}
    </div>
  )
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'flagged', 'date', 'status',
      'ticketTypeName', 'resourceTypeName', 'clientName', 'weight', 'weightTicketNumber', 'driverName', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  onSortChange: PropTypes.func.isRequired
}

export default function TicketsTable ({
  onPageRequest,
  sort,
  onSortChange,
  tickets,
  onBatchDelete,
  onBatchEdit,
  fakeCount
}) {
  const [selectedTickets, setSelectedTickets] = useState([])
  const [allSelected, setAllSelected] = useState(false)

  const selectTicket = (id) => {
    const idx = selectedTickets.indexOf(id)
    const selected = [...selectedTickets]
    if (idx === -1) {
      selected.push(id)
      setSelectedTickets(selected)
    } else {
      selected.splice(idx, 1)
      setSelectedTickets(selected)
      setAllSelected(false)
    }
  }

  const selectAll = () => {
    if (allSelected) {
      setSelectedTickets([])
      setAllSelected(false)
    } else {
      setSelectedTickets(tickets.nodes.map(ticket => ticket.id))
      setAllSelected(true)
    }
  }

  return (
    <div>
      <div className='dropdown'>
        <button className='btn btn-lg btn-alert dropdown-toggle' type='button' id='batchActions'
          data-toggle='dropdown' disabled={!selectedTickets.length}>
          <span className='space-right'>Bulk Actions ({selectedTickets.length} tickets)</span>
          <span className='caret' />
        </button>
        <ul className='dropdown-menu'>
          <li><button type='button' onClick={() => onBatchEdit(selectedTickets)}>EDIT {selectedTickets.length} TICKETS</button></li>
          <li><button type='button' className='text-danger' onClick={() => onBatchDelete(selectedTickets)}>
            DELETE {selectedTickets.length} TICKETS
          </button></li>
        </ul>
      </div>
      <div className='row'>
        <div className='col-xs-12 table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th width='4%'>
                  <input
                    type='checkbox'
                    onChange={selectAll}
                    checked={allSelected}
                  />
                </th>
                <th width='3%'>
                  <TableHeader
                    label='ID'
                    columnName='customId'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='3%'>
                  <TableHeader
                    label='Flagged'
                    columnName='flagged'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='5%'>
                  <TableHeader
                    label='Status'
                    columnName='status'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='1%' className='cell-no-padding-x'></th>
                <th width='8%'>
                  <TableHeader
                    label='Billing Status'
                    columnName='billingStatus'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='7%'>
                  <TableHeader
                    label='Date'
                    columnName='date'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='8%'>
                  <TableHeader
                    label='Ticket Type'
                    columnName='ticketTypeName'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='8%'>
                  <TableHeader
                    label='Asset Type'
                    columnName='resourceTypeName'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='8%'>
                  <TableHeader
                    label='Account Name'
                    columnName='clientName'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='5%'>
                  <TableHeader
                    label='Job ID'
                    columnName='jobId'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='10%'>
                  <TableHeader
                    label='Address'
                    columnName='address'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='4%'>
                  <TableHeader
                    label='Weight'
                    columnName='weight'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='4%'>
                  <TableHeader
                    label='Weight Ticket Number'
                    columnName='weightTicketNumber'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='8%'>
                  <TableHeader
                    label='Driver'
                    columnName='driverName'
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </th>
                <th width='7%'>Action</th>
              </tr>
            </thead>
            <tbody>
              {tickets.nodes.length ? null : <tr><td colSpan={12}><NoResults /></td></tr>}
              {tickets.nodes.map((ticket) => {
                const { account, job, ticketType, resourceType, driverAssigned } = ticket
                const ticketPath = `/hauler/tickets/${ticket.id}`
                return (
                  <tr key={ticket.id}>
                    <td>
                      <input
                        type='checkbox'
                        onChange={() => selectTicket(ticket.id)}
                        checked={selectedTickets.indexOf(ticket.id) !== -1}
                      />
                    </td>
                    <td>
                      <Link to={ticketPath}>{ticket.customId}</Link>
                    </td>
                    <td>
                      {ticket.flagged && <Link to={ticketPath}><TicketFlag flagged={ticket.flagged} /></Link>}
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <TicketStatusLabel
                          status={ticket.status}
                          hasActiveException={ticket.hasActiveException}
                          exceptionMessage={ticket.exceptionMessage}
                        />
                      </Link>
                    </td>
                    <td className='cell-no-padding-x'>
                      <BillingStatusIcon billingStatus={ticket.billingStatus} />
                    </td>
                    <td>{enumToTitleCase(ticket.billingStatus)}</td>
                    <td>
                      <div>
                        {dayjs(ticket.date).format('MMM D, YYYY')}
                        <br />
                        <small>
                          {
                            ticket.requestedStartTime
                              ? `${stringToTime(ticket.requestedStartTime)}-${stringToTime(ticket.requestedEndTime)}`
                              : null
                          }
                        </small>
                      </div>
                    </td>
                    <td>
                      {ticketType.name} ({ticketType.shortCode})
                    </td>
                    <td>
                      {resourceType.name} ({resourceType.shortCode})
                    </td>
                    <td>
                      <Link to={`/hauler/clients/${account.id}`}>{account.name}</Link>
                    </td>
                    <td>
                      <Link to={`/hauler/jobs/${job.id}`}>{job.customId}</Link>
                    </td>
                    <td>
                      <div>
                        {job?.location?.addressLine1 || ''} {job?.location?.city || ''}, {job?.location?.state || ''} {job?.location?.zip || ''}
                        {job.addressDetails ? `(${job.addressDetails})` : null }
                      </div>
                    </td>
                    <td>
                      <div>{ticket.weight ? `${ticket.weight} (${enumToTitleCase(ticket.weightUnitsOfMeasure)})` : 'N/A'}</div>
                    </td>
                    <td>
                      <div>{ticket.weightTicketNumber ? ticket.weightTicketNumber : 'N/A'}</div>
                    </td>
                    <td>
                      {(() => {
                        if (ticket.driverAssigned) {
                          return `${driverAssigned.firstName} ${driverAssigned.lastName}`
                        }
                        return 'None'
                      })()}
                    </td>
                    <td>
                      <div className='btn-group'>
                        <Link to={ticketPath} className='btn btn-sm btn-primary-dk'>
                          Details
                        </Link>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {tickets.nodes.length > 0 && <CursorPagination
            pageInfo={tickets.pageInfo}
            totalCount={tickets.totalCount}
            onPageRequest={onPageRequest}
            fakeCount={fakeCount}
                                       />}
        </div>
      </div>
    </div>
  )
}

TicketsTable.propTypes = {
  onPageRequest: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  tickets: PropTypes.object.isRequired,
  onBatchDelete: PropTypes.func,
  onBatchEdit: PropTypes.func,
  sort: PropTypes.shape({
    column: PropTypes.oneOf(['customId', 'flagged', 'date', 'status', 'ticketTypeName',
      'resourceTypeName', 'clientName', 'weight', 'weightTicketNumber', 'driverName', '']).isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
