import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import NormalLayoutContainer from '../../../../shared/NormalLayoutContainer'
import { PageTitle } from '../../../../../components/page-title'
import { Spinner } from '../../../../shared/Spinner'
import notify from '../../../../../utilities/notify'
import useMutation from '../../../../../hooks/useMutation'
import { REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION } from '../../sagas/currentHaulerTeamMemberSaga'
import updateUser from '../../../../../graphql/mutations/updateUser'
import HaulerTeamMemberEditForm from './components/HaulerTeamMemberEditForm'
import HaulerTeamMemberEditExplanation from './components/HaulerTeamMemberEditExplanation'
import { snakeCasedObjectToCamelCasedObject } from '../../../../../utilities/randomUtilities'
import { onlyNumbers } from '../../../../../utilities/stringUtilities'

export default function HaulerTeamEditPage () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const { mutate: updateUserMutation, isLoading } = useMutation(updateUser, {
    onSuccess () {
      notify('success', 'Team Member Updated Successfully')
      history.push(`/hauler/team/${id}`)
    },
    onError () {
      notify('error', 'Failed to update user')
    }
  })

  const { haulerTeamMemberEditForm, currentHaulerTeamMember } = useSelector(
    ({ currentHaulerTeamMember, form }) => ({
      haulerTeamMemberEditForm: form.haulerTeamMemberEditForm,
      currentHaulerTeamMember
    }))

  const {
    first_name: firstName,
    isFetching,
    last_name: lastName
  } = currentHaulerTeamMember

  const cancelEdits = useCallback(() => {
    history.push(`/hauler/team/${id}`)
  }, [history, id])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    haulerTeamMemberEditForm.values.primary_phone = `+1${onlyNumbers(haulerTeamMemberEditForm.values.pretty_primary_phone)}`
    const inputValues = {
      id,
      ...haulerTeamMemberEditForm.values
    }
    updateUserMutation(snakeCasedObjectToCamelCasedObject(inputValues))
  }, [id, haulerTeamMemberEditForm, updateUserMutation])

  useEffect(() => {
    dispatch({ type: REQUEST_CURRENT_HAULER_TEAM_MEMBER_ACTION, payload: id })
  }, [dispatch, id])

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <div className='container-fluid'>
          <PageTitle title={`Team Member ${firstName} ${lastName} Profile`}>
            <button
              className='btn btn-link pull-right push-down-very-small'
              onClick={cancelEdits}>
              Cancel
            </button>
          </PageTitle>

          <Spinner isFetching={isFetching}>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                <HaulerTeamMemberEditForm
                  isFetching={isFetching || isLoading}
                  team_member={currentHaulerTeamMember}
                  onSubmit={handleSubmit}
                />
              </div>
              <div className='col-xs-12 col-md-4 col-md-offset-1'>
                <HaulerTeamMemberEditExplanation />
              </div>
            </div>
          </Spinner>
        </div>
      </NormalLayoutContainer>
    </div>
  )
}

HaulerTeamEditPage.propTypes = {}
