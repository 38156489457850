// Keys we use for the 'react-query' library, these need to be unique, so we are throwing them into an object to avoid
// accidentally adding duplicate keys https://react-query.tanstack.com/guides/query-keys.

/* eslint sort-keys: "error" -- Alphabetizing here to help us prevent duplicates */
const QUERY_KEYS = {
  activities: 'activities',
  activitiesPages: 'activitiesPages',
  allAssets: 'allAssets',
  assets: 'assets',
  assetsOnJob: 'assetsOnJob',
  assetsOnSite: 'assetsOnSite',
  billingStatusEnum: 'billingStatusEnum',
  client: 'client',
  clientImport: 'clientImport',
  clientImportRows: 'clientImportRows',
  clientImports: 'clientImports',
  clientJobs: 'clientJobs',
  clientQuickbooksCustomer: 'clientQuickbooksCustomer',
  clients: 'clients',
  createTicketPage: 'createTicketPage',
  dispatchPage: 'dispatchPage',
  dispatchResources: 'dispatchResources',
  dispatchSites: 'dispatchSites',
  dispatchTicket: 'dispatchTicket',
  dispatchTickets: 'dispatchTickets',
  dispatcherPayment: 'dispatcherPayment',
  dispatcherTransactions: 'dispatcherTransactions',
  editTicketPage: 'editTicketPage',
  feeType: 'feeType',
  feeTypeQuickbooksItem: 'feeTypeQuickbooksItem',
  feeTypes: 'feeTypes',
  hauler: 'hauler',
  invoice: 'invoice',
  invoiceByUUID: 'invoiceByUUID',
  invoiceInformation: 'invoiceInformation',
  invoicePaymentDetails: 'invoicePaymentDetails',
  invoicePreview: 'invoicePreview',
  invoiceSettings: 'invoiceSettings',
  invoices: 'invoices',
  job: 'job',
  jobsSearch: 'jobs',
  notifications: 'notifications',
  paymentTerms: 'paymentTerms',
  quickbooksCustomerTypesAndTaxCodes: 'quickbooksCustomerTypesAndTaxCodes',
  quickbooksFeeTypes: 'quickbooksFeeTypes',
  quickbooksTaxCodes: 'quickbooksTaxCodes',
  resourceAndTicketTypes: 'resourceAndTicketTypes',
  resourceImports: 'resourceImports',
  resourceType: 'resourceType',
  resourceTypes: 'resourceTypes',
  resourcesCharts: 'resourcesCharts',
  resourcesSearch: 'resourcesSearch',
  site: 'site',
  siteTypes: 'siteTypes',
  sites: 'sites',
  ticket: 'ticket',
  ticketFeesDiscountAndTax: 'ticketFeesDiscountAndTax',
  ticketNotifications: 'ticketNotifications',
  ticketType: 'ticketType',
  ticketTypes: 'ticketTypes',
  ticketsSearch: 'ticketsSearch',
  user: 'user',
  users: 'users',
  wastepayCustomerCardList: 'wastePayCustomerCardList',
  wastepayReceiptPreview: 'wastepayReceiptPreview',
  wastepaySessionToken: 'wastepaySessionToken',
  wastepayTransaction: 'wastepayTransaction',
  wastepayTransactions: 'wastepayTransactions',
  weightUnits: 'weightUnits'
}

export default QUERY_KEYS
