import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import NormalLayoutContainer from '../shared/NormalLayoutContainer'
import PageTitle from '../../components/page-title'
import { Spinner } from '../shared/Spinner'
import notify from '../../utilities/notify'
import FormExplanation from '../shared/FormExplanation'
import ticketSearchQuery from '../../graphql/queries/ticketsSearch'
import { TICKET_BILLING_STATUSES } from '../../utilities/constants'
import QUERY_KEYS from '../../graphql/queryKeys'
import { REQUEST_HAULER_TEAM_ACTION } from '../hauler/team/sagas/haulerTeamSaga'
import BulkTicketsEditForm from '../../components/bulk-edit-tickets-form'
import BatchEditTicketsTable from '../hauler/tickets/batch/edit/components/BatchEditTicketsTable'
import useMutation from '../../hooks/useMutation'
import useQuery from '../../hooks/useQuery'
import ticketTypesQuery from '../../graphql/queries/ticketTypes'
import resourceTypesQuery from '../../graphql/queries/resourceTypes'
import usersQuery from '../../graphql/queries/users.js'
import { captureErrorAndNotify } from '../../utilities/errorHandlers'
import updateTicketsMutation from '../../graphql/mutations/updateTickets'
import { ROUTES } from '../../routes'
import weightUnitsQuery from '../../graphql/queries/weightUnits.js'

const pageSize = 25

export default function BulkEditTicketsPage () {
  const [fakeCount, setFakeCount] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const { hauler } = useSelector(({ user }) => ({ hauler: user.hauler }))

  const { mutate: batchEditTickets, isLoading: batchEditTicketsLoading } = useMutation(updateTicketsMutation, {
    onSuccess () {
      notify('success', 'Updated tickets successfully!')
      history.goBack()
    },
    onError (error) {
      captureErrorAndNotify(error, 'Failed to update tickets')
    }
  })

  const { data: tickets, isFetching: ticketsFetching } = useQuery([
    QUERY_KEYS.ticketsSearch,
    hauler.id,
    pageSize,
    { column: 'date', direction: 'desc' },
    { ticketIds: queryParams.get('ids') }
  ],
  ticketSearchQuery,
  {
    enabled: Boolean(queryParams.sort),
    placeholderData: { ticketsSearch: { nodes: [] } },
    onError (error) {
      captureErrorAndNotify(error, 'failed to retrieve tickets')
    }
  })

  const { data: ticketTypes, isFetching: ticketTypesFetching } = useQuery(
    [QUERY_KEYS.ticketTypes, { haulerId: hauler.id, status: 'ENABLED' }],
    ticketTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'failed to retrieve ticket types')
      },
      placeholderData: { ticketTypes: [] }
    })

  const { data: resourceTypes, isFetching: resourceTypesFetching } = useQuery(
    [QUERY_KEYS.resourceTypes, { haulerId: hauler.id, status: 'ENABLED' }],
    resourceTypesQuery,
    {
      onError (error) {
        captureErrorAndNotify(error, 'failed to retrieve resource types')
      },
      placeholderData: { resourceTypes: [] }
    })

  const { data: { users } = {}, isFetching: usersFetching } = useQuery([
    QUERY_KEYS.users,
    hauler.id,
    true,
    null,
    null,
    hauler.showDriversOnly ? 'hauler_driver' : null
  ],
  usersQuery,
  {
    onError (error) {
      captureErrorAndNotify(error, 'Unable to retrieve user list')
    },
    placeholderData: { users: { nodes: [] } }
  })

  const { data: weightUnitData, isFetching: isWeightUnitsFetching } = useQuery(
    [QUERY_KEYS.weightUnits, hauler?.id],
    weightUnitsQuery
  )

  useEffect(function toggleFakePagination () {
    if (!tickets.ticketsSearch.totalCount || ticketsFetching || fakeCount !== null) return
    setFakeCount({ start: 1, end: Math.min(pageSize, tickets.ticketsSearch.totalCount) })
  }, [tickets, ticketsFetching, fakeCount, setFakeCount])

  const checkInvoiced = useMemo(() => {
    return tickets.ticketsSearch.nodes.some(tckt => tckt?.invoice?.qboInvoiced || tckt?.invoice?.dispatcherInvoiceNumber)
  }, [tickets])

  const handleFormSubmit = (values) => {
    if (values.driverId && values.driverId === 'null') {
      values.driverId = null
    }

    if (values.weight) {
      values.weight = parseFloat(values.weight)
    }

    const { enabled, ...vals } = values
    const submitValues = Object.keys(vals).filter((key) => (enabled[key]) ||
    (key === 'requestedEndTime' && enabled.requestedTimesEnabled) ||
    (key === 'requestedStartTime' && enabled.requestedTimesEnabled)).reduce((cur, key) => {
      return Object.assign(cur, { [key]: vals[key] })
    }, {})

    const formattedIds = queryParams.get('ids').split(',').map(id => ({
      id
    }))

    batchEditTickets({ ...submitValues, ...{ ids: formattedIds } })
  }

  useEffect(function onInitialRender () {
    dispatch({ type: REQUEST_HAULER_TEAM_ACTION, payload: {} })
  }, [dispatch, queryParams])

  const isFetching = ticketTypesFetching || resourceTypesFetching || usersFetching || ticketsFetching || batchEditTicketsLoading || isWeightUnitsFetching

  return (
    <div>
      <NormalLayoutContainer showBackLink>
        <PageTitle>
          <div className='dis-page-title-flex'>
            <div>Bulk Edit Tickets</div>
            <Link className='dis-btn dis-btn-blank dis-btn-lg' to={ROUTES.ticketSearch}>
              Cancel
            </Link>
          </div>
        </PageTitle>

        <Spinner isFetching={isFetching}>
          <div className='dis-form-page-container'>
            <div className='dis-form-container-left'>
              <BulkTicketsEditForm
                isFetching={isFetching}
                weightUnits={weightUnitData?.weightUnits || []}
                onSubmit={handleFormSubmit}
                resourceTypes={resourceTypes?.resourceTypes}
                ticketTypes={ticketTypes?.ticketTypes}
                billingStatuses={TICKET_BILLING_STATUSES}
                users={users?.nodes}
                checkInvoiced={checkInvoiced}
                initialValues={{
                  date: null,
                  ticketTypeId: null,
                  resourceTypeId: null,
                  driverId: null,
                  weight: null,
                  weightUnit: null,
                  billingNotes: null,
                  requestedEndTime: null,
                  requestedStartTime: null,
                  billingStatus: null,
                  enabled: {
                    date: false,
                    ticketTypeId: false,
                    resourceTypeId: false,
                    driverId: false,
                    weight: false,
                    billingNotes: false,
                    requestedTimesEnabled: false,
                    billingStatus: false,
                    weightUnit: false
                  }
                }}
              />
            </div>
            <div className='dis-form-container-right'>
              <FormExplanation
                messages={[
                  'Bulk ticket edits apply the same form values to many tickets.',
                  ' - Select the fields you want to edit on several tickets'
                ]}
              />
            </div>
          </div>
          <div className='push-down-small'>
            <BatchEditTicketsTable
              fakeCount={fakeCount}
              tickets={tickets.ticketsSearch}
            />
          </div>
        </Spinner>
      </NormalLayoutContainer>
    </div>
  )
}
