import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import dayjs from '../../../../../../utilities/dayjs'
import { stringToTime, enumToTitleCase } from '../../../../../../utilities/stringUtilities'
import TicketStatusLabel from '../../../../../../components/TicketStatusLabel'
import { prettyAddress } from '../../../../../../utilities/locationUtilities'
import CursorPagination from '../../../../../../components/cursor-pagination'
import BillingStatusIcon from '../../../../../../components/billing-status-display'

export default function BatchEditTicketsTable ({ tickets, fakeCount }) {
  return (
    <div className='panel panel-default'>
      <div className='panel-heading'>
        <div className='container-fluid'>
          <div>
            <h4 className='pull-left'>
              {tickets.nodes.length} tickets
            </h4>
          </div>
        </div>
      </div>
      <div className='panel-body table-responsive'>
        <table className='table table-striped push-down-super-small'>
          <thead>
            <tr>
              <th width='5%'>ID</th>
              <th width='5%'>Status</th>
              <th width='1%' className='cell-no-padding-x' />
              <th width='8%' className='cell-no-padding-left'>Billing Status</th>
              <th width='9%'>Date</th>
              <th width='10%'>Ticket Type</th>
              <th width='10%'>Asset Type</th>
              <th width='10'>Account Name</th>
              <th width='5%'>Job Id</th>
              <th width='15%'>Address</th>
              <th width='5%'>Weight</th>
              <th width='10%'>Driver</th>
              <th width='5%'>View Details</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              if (tickets.nodes.length === 0) {
                return (<tr>
                  <td colSpan={12}>
                    <h4 className='text-center'>No Tickets</h4>
                  </td>
                </tr>)
              }
              return tickets.nodes.map((ticket) => {
                const ticketPath = `/hauler/tickets/${ticket.id}`
                return (
                  <tr key={ticket.id}>
                    <td>
                      <Link to={ticketPath}>{ticket.customId}</Link>
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <TicketStatusLabel
                          status={ticket.status}
                          exceptionMessage={ticket.exceptionMessage}
                          hasActiveException={ticket.hasActiveException}
                        />
                      </Link>
                    </td>
                    <td className='cell-no-padding-x'>
                      <BillingStatusIcon billingStatus={ticket.billingStatus} />
                    </td>
                    <td>{enumToTitleCase(ticket.billingStatus)}</td>
                    <td>
                      <div>
                        {dayjs(ticket.date).format('MMM D, YYYY')}
                        <br />
                        <small>
                          {
                            ticket.requestedStartTime
                              ? `${stringToTime(ticket.requestedStartTime)}-${stringToTime(ticket.requestedEndTime)}`
                              : null
                          }
                        </small>
                      </div>
                    </td>
                    <td>
                      {ticket.ticketType.name} ({ticket.ticketType.shortCode})
                    </td>
                    <td>
                      {ticket.resourceType.name} ({ticket.resourceType.shortCode})
                    </td>
                    <td>
                      {ticket.account.name}
                    </td>
                    <td>
                      {ticket.job.id}
                    </td>
                    <td>
                      {prettyAddress(ticket.job.location)}
                    </td>
                    <td>
                      {ticket.weight ? `${ticket.weight} (${enumToTitleCase(ticket.weightUnitsOfMeasure)})` : 'N/A'}
                    </td>
                    <td>
                      { ticket.driverAssigned ? `${ticket.driverAssigned.firstName} ${ticket.driverAssigned.lastName}` : 'None'}
                    </td>
                    <td>
                      <Link to={ticketPath}>
                        <i className='material-icons medium-icon'>info</i>
                      </Link>
                    </td>
                  </tr>
                )
              })
            })()}
          </tbody>
        </table>
        {/* <Pagination onPageRequest={onPageRequest} pagination={pagination} /> */}
        {tickets.nodes.length > 0 && <CursorPagination
          pageInfo={tickets.pageInfo}
          totalCount={tickets.totalCount}
          onPageRequest={() => {}}
          fakeCount={fakeCount}
                                     />}
      </div>
    </div>
  )
}

BatchEditTicketsTable.propTypes = {
  tickets: PropTypes.any.isRequired,
  fakeCount: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired
  })
}
