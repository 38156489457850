import { intercomConnection } from '../../intercomConnection'
import { loader } from 'graphql.macro'

export default async function updateUser ({ dataLoaders, variables }) {
  const schema = loader('./updateUser.graphql')
  const response = await dataLoaders.dispatcher.graphQLClient.request(schema, {
    input: {
      userId: variables.id,
      userFields: {
        firstName: variables.firstName,
        lastName: variables.lastName,
        email: variables.email,
        primaryPhone: variables.primaryPhone,
        role: variables.role,
        trackLocation: variables.trackLocation
      }
    }
  })
  intercomConnection.trackEvent('user_updated', {})
  intercomConnection.trackEvent('team_member_updated', {
    id: variables.id,
    email: variables.email
  })
  return response.updateUser
}
